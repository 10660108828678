define("ds-ssdc/routes/dashboard/congress/webcasts", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class DashboardCongressWebcastsRoute extends Ember.Route {}

  _exports.default = DashboardCongressWebcastsRoute;
});