define("ds-ssdc/components/file-upload-model", ["exports", "@glimmer/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _class;

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    {{#if @showModel}}
  <ModalDialog
              @onClose={{action (mut @showModel false)}}
              @targetAttachment="center"
              @translucentOverlay={{true}}
          >
      <div class="modal-header">{{@header}}</div>
      <div class="new-session-modal">
          <div class="session-details">
              <div class="header header-large session-header">{{@modelTitle}}</div>
              <span class="session-category">{{@modelsubTitle}}</span>
          </div>
          <div class="new-session">
              <FileInput
                  @accept=".csv"
                  @required={{true}}
                  @uploadURL={{@uploadURL}}
                  @labelText="Choose CSV FIle"
                  @onUploadSuccess={{this.uploadSuccess}}
                  @onUploadError={{this.uploadFailure}}
                      as |isUploading|>
                  <br/>
                  <button class="btn upload-data" type="submit">
                  {{#if isUploading}}
                      <FaIcon @icon="spinner" @spin={{true}} />
                  {{/if}}
                      Upload
                  </button>
              </FileInput>
          </div>
      </div>
  </ModalDialog>
  {{/if}}
  */
  {
    id: "F+XIwotf",
    block: "{\"symbols\":[\"isUploading\",\"@showModel\",\"@header\",\"@modelTitle\",\"@modelsubTitle\",\"@uploadURL\"],\"statements\":[[4,\"if\",[[23,2,[]]],null,{\"statements\":[[5,\"modal-dialog\",[],[[\"@onClose\",\"@targetAttachment\",\"@translucentOverlay\"],[[28,\"action\",[[23,0,[]],[28,\"mut\",[[23,2,[]],false],null]],null],\"center\",true]],{\"statements\":[[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"modal-header\"],[8],[1,[23,3,[]],false],[9],[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"new-session-modal\"],[8],[0,\"\\n        \"],[7,\"div\",true],[10,\"class\",\"session-details\"],[8],[0,\"\\n            \"],[7,\"div\",true],[10,\"class\",\"header header-large session-header\"],[8],[1,[23,4,[]],false],[9],[0,\"\\n            \"],[7,\"span\",true],[10,\"class\",\"session-category\"],[8],[1,[23,5,[]],false],[9],[0,\"\\n        \"],[9],[0,\"\\n        \"],[7,\"div\",true],[10,\"class\",\"new-session\"],[8],[0,\"\\n            \"],[5,\"file-input\",[],[[\"@accept\",\"@required\",\"@uploadURL\",\"@labelText\",\"@onUploadSuccess\",\"@onUploadError\"],[\".csv\",true,[23,6,[]],\"Choose CSV FIle\",[23,0,[\"uploadSuccess\"]],[23,0,[\"uploadFailure\"]]]],{\"statements\":[[0,\"\\n                \"],[7,\"br\",true],[8],[9],[0,\"\\n                \"],[7,\"button\",true],[10,\"class\",\"btn upload-data\"],[10,\"type\",\"submit\"],[8],[0,\"\\n\"],[4,\"if\",[[23,1,[]]],null,{\"statements\":[[0,\"                    \"],[5,\"fa-icon\",[],[[\"@icon\",\"@spin\"],[\"spinner\",true]]],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"                    Upload\\n                \"],[9],[0,\"\\n            \"]],\"parameters\":[1]}],[0,\"\\n        \"],[9],[0,\"\\n    \"],[9],[0,\"\\n\"]],\"parameters\":[]}],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    meta: {
      moduleName: "ds-ssdc/components/file-upload-model.hbs"
    }
  });

  let FileUploadModelComponent = (_dec = Ember._action, _dec2 = Ember._action, (_class = class FileUploadModelComponent extends _component.default {
    uploadSuccess() {
      alert("File uploaded successfully");
    }

    uploadFailure() {
      alert("File uploaded failed, please try again");
    }

  }, (_applyDecoratedDescriptor(_class.prototype, "uploadSuccess", [_dec], Object.getOwnPropertyDescriptor(_class.prototype, "uploadSuccess"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "uploadFailure", [_dec2], Object.getOwnPropertyDescriptor(_class.prototype, "uploadFailure"), _class.prototype)), _class));
  _exports.default = FileUploadModelComponent;

  Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, FileUploadModelComponent);
});