define("ds-ssdc/routes/dashboard/congress/content", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class DashboardCongressContentRoute extends Ember.Route {}

  _exports.default = DashboardCongressContentRoute;
});