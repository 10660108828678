define("ds-ssdc/helpers/congress-date", ["exports", "moment"], function (_exports, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Helper.helper(function congressDate(params
  /*, hash*/
  ) {
    const startDate = (0, _moment.default)(params[0]);
    const endDate = (0, _moment.default)(params[1]);

    if (startDate.get('year') !== endDate.get('year')) {
      return `${startDate.format('Do MMM YYYY')} to ${endDate.format('Do MMM YYYY')}`;
    }

    if (startDate.get('month') !== endDate.get('month')) {
      return `${startDate.format('Do MMM')} to ${endDate.format('Do MMM YYYY')}`;
    }

    return `${startDate.format('Do')} to ${endDate.format('Do MMM YYYY')}`;
  });

  _exports.default = _default;
});