define("ds-ssdc/controllers/dashboard/congress/webcasts", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _temp;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let DashboardCongressWebcastsController = (_dec = Ember.inject.service, _dec2 = Ember._tracked, _dec3 = Ember._tracked, _dec4 = Ember._tracked, _dec5 = Ember._tracked, _dec6 = Ember._action, _dec7 = Ember._action, _dec8 = Ember._action, (_class = (_temp = class DashboardCongressWebcastsController extends Ember.Controller {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "ajax", _descriptor, this);

      _initializerDefineProperty(this, "verifyOptions", _descriptor2, this);

      _initializerDefineProperty(this, "selectedVerifyOption", _descriptor3, this);

      _defineProperty(this, "sessionsTable", null);

      _initializerDefineProperty(this, "refreshTable", _descriptor4, this);

      _initializerDefineProperty(this, "contentColumns", _descriptor5, this);
    }

    async setColumnFilters() {
      try {
        const data = await this.ajax.request('sessions/filters', {
          method: "POST",
          data: {
            "congress_id": this.model.congressId
          },
          contentType: "application/json;charset=utf-8",
          dataType: "json"
        });

        for (const key in data) {
          const typeIndex = this.contentColumns.findIndex(pc => pc.valuePath === key);

          if (typeIndex > -1) {
            const searchOptions = [];

            for (const value in data[key]) {
              searchOptions.push({
                id: value,
                name: data[key][value]
              });
            }

            this.set(`contentColumns.${typeIndex}.searchType`, 'select');
            this.set(`contentColumns.${typeIndex}.searchOptions`, searchOptions);
          }
        }
      } catch (error) {
        throw error;
      }
    }

    setTableReference(sessionsTable) {
      this.sessionsTable = sessionsTable;
      this.setColumnFilters();
    }

    async modifySession(action, option) {
      const selectedIds = this.sessionsTable.selectedRows.map(r => r.content.ferma_id);
      const url = option === 'slides' ? 'screens/verify' : 'slides/verify';

      if (!selectedIds.length && action != 'download') {
        alert("No ids selected");
        return;
      }

      switch (action) {
        case 'track':
          break;

        case 'upload':
          try {
            const sessionIds = this.sessionsTable.selectedRows.map(r => r.content.deck_id);
            await this.ajax.request('sessions/bulk_upload', {
              method: "POST",
              data: {
                "congress_id": this.model.congressId,
                "session_ids": sessionIds
              },
              contentType: "application/json;charset=utf-8",
              dataType: "json"
            });
            this.incrementProperty('refreshTable');
            alert("Session verified successfully");
          } catch (error) {
            this.incrementProperty('refreshTable');
            alert("Couldn't verify sessions");
          }

          break;

        case 'download':
          try {
            const {
              xhr
            } = this.ajax.request('sessions/download', {
              method: "POST",
              processData: false,
              data: {
                congress_id: this.model.congressId
              },
              dataType: 'binary',
              xhrFields: {
                responseType: 'blob'
              }
            });
            const data = await xhr.promise();
            let url = window.URL.createObjectURL(new Blob([data], {
              type: "text/csv"
            }));
            let a = document.createElement('a');
            a.href = url;
            a.download = 'webcasts.csv';
            document.body.append(a);
            a.click();
            a.remove();
            window.URL.revokeObjectURL(url);
          } catch (error) {
            alert("Couldn't download sessions");
          }

          break;

        case 'gen-pdf':
          try {
            const sessionIds = this.sessionsTable.selectedRows.map(r => r.content.deck_id);
            await this.ajax.request('sessions/bulk_edit', {
              method: "PATCH",
              data: {
                "session_ids": sessionIds,
                "transcription_status": "Verified"
              },
              contentType: "application/json;charset=utf-8",
              dataType: "json"
            });
            this.incrementProperty('refreshTable');
            alert("Pdf generated successfully");
          } catch (error) {
            this.incrementProperty('refreshTable');
            alert("Couldn't generate Pdfs");
          }

          break;

        case 'gen-transcripts':
          try {
            const sessionIds = this.sessionsTable.selectedRows.map(r => r.content.deck_id);
            await this.ajax.request('sessions/bulk_edit', {
              method: "PATCH",
              data: {
                "session_ids": sessionIds,
                "video_extraction_status": "Verified"
              },
              contentType: "application/json;charset=utf-8",
              dataType: "json"
            });
            this.incrementProperty('refreshTable');
            alert("Transcripts generated successfully");
          } catch (error) {
            this.incrementProperty('refreshTable');
            alert("Couldn't generate Transcripts");
          }

          break;

        case 'auto-verify':
          try {
            await this.ajax.request(url, {
              method: "POST",
              data: {
                "session_ids": selectedIds
              },
              contentType: "application/json;charset=utf-8",
              dataType: "json"
            });
            this.incrementProperty('refreshTable');
            this.selectedVerifyOption = 'auto-verify';
            alert("Session verified successfully");
          } catch (error) {
            this.incrementProperty('refreshTable');
            this.selectedVerifyOption = 'auto-verify';
            alert("Couldn't verify sessions");
          }

          break;
      }
    }

    noOp() {}

  }, _temp), (_descriptor = _applyDecoratedDescriptor(_class.prototype, "ajax", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "verifyOptions", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return ['Auto-verify', 'slides', 'transcriptions'];
    }
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "selectedVerifyOption", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return 'Auto-verify';
    }
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "refreshTable", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return 0;
    }
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "contentColumns", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return Ember.A([{
        label: "Select ",
        width: "35px",
        valuePath: "",
        component: 'column-select-all',
        cellComponent: 'row-select',
        sortable: false,
        searchable: false
      }, {
        label: "Ferma Id",
        width: "65px",
        valuePath: "ferma_id",
        cellComponent: "session-edit",
        sortable: true
      }, {
        label: "Abs Id",
        width: "60px",
        valuePath: "abs_id",
        sortable: true
      }, {
        label: "Title",
        width: "200px",
        valuePath: "title",
        cellClassNames: 'break-word',
        sortable: true
      }, {
        label: "Session Date",
        width: "85px",
        valuePath: "session_date",
        cellComponent: "date-row",
        sortable: true
      }, {
        label: "Type",
        width: "85px",
        valuePath: "type",
        sortable: true
      }, {
        label: "SSD Tenant",
        width: "65px",
        valuePath: "ssd_tenant",
        cellComponent: "ssd-tenants",
        cellClassNames: 'break-word',
        sortable: true
      }, {
        label: "Target",
        width: "65px",
        valuePath: "target",
        sortable: true
      }, {
        label: "Owner",
        width: "90px",
        valuePath: "owner",
        cellClassNames: 'break-word',
        sortable: true
      }, {
        label: "Verifier",
        width: "90px",
        valuePath: "verifier",
        cellClassNames: 'break-word',
        sortable: true
      }, {
        label: "Status",
        width: "95px",
        valuePath: "status",
        sortable: true
      }, {
        label: "Download",
        width: "85px",
        valuePath: "",
        cellComponent: "row-webcast-download",
        sortable: true,
        align: "center"
      }]);
    }
  }), _applyDecoratedDescriptor(_class.prototype, "setTableReference", [_dec6], Object.getOwnPropertyDescriptor(_class.prototype, "setTableReference"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "modifySession", [_dec7], Object.getOwnPropertyDescriptor(_class.prototype, "modifySession"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "noOp", [_dec8], Object.getOwnPropertyDescriptor(_class.prototype, "noOp"), _class.prototype)), _class));
  _exports.default = DashboardCongressWebcastsController;
});