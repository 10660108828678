define("ds-ssdc/components/table-loader", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <div class="table-loader">
  	<div class="message">
  		<img src="/images/f-logo.png" alt="loader" class="scale-up-center" />
  	</div>
  </div>
  */
  {
    id: "K+1eyXNM",
    block: "{\"symbols\":[],\"statements\":[[7,\"div\",true],[10,\"class\",\"table-loader\"],[8],[0,\"\\n\\t\"],[7,\"div\",true],[10,\"class\",\"message\"],[8],[0,\"\\n\\t\\t\"],[7,\"img\",true],[10,\"src\",\"/images/f-logo.png\"],[10,\"alt\",\"loader\"],[10,\"class\",\"scale-up-center\"],[8],[9],[0,\"\\n\\t\"],[9],[0,\"\\n\"],[9]],\"hasEval\":false}",
    meta: {
      moduleName: "ds-ssdc/components/table-loader.hbs"
    }
  });

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());

  _exports.default = _default;
});