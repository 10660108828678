define("ds-ssdc/components/dropdown-trigger", ["exports", "ember-power-select/components/power-select/trigger"], function (_exports, _trigger) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    {{#if @select.selected}}
    {{#if @selectedItemComponent}}
      {{component @selectedItemComponent extra=(readonly @extra) option=(readonly @select.selected) select=(readonly @select)}}
    {{else}}
      <span class="ember-power-select-selected-item">{{yield @select.selected @select}}</span>
    {{/if}}
    {{#if (and @allowClear (not @select.disabled))}}
      <span class="ember-power-select-clear-btn" {{on "mousedown" this.clear}} {{on "touchstart" this.clear}}>&times;</span>
    {{/if}}
  {{else}}
    {{component @placeholderComponent placeholder=@placeholder}}
  {{/if}}
  <FaIcon @class="dropdown-icon" @icon={{if @select.isOpen 'angle-up' 'angle-down'}}></FaIcon>
  */
  {
    id: "nrioUMuq",
    block: "{\"symbols\":[\"@placeholder\",\"@placeholderComponent\",\"@select\",\"&default\",\"@extra\",\"@selectedItemComponent\",\"@allowClear\"],\"statements\":[[4,\"if\",[[23,3,[\"selected\"]]],null,{\"statements\":[[4,\"if\",[[23,6,[]]],null,{\"statements\":[[0,\"    \"],[1,[28,\"component\",[[23,6,[]]],[[\"extra\",\"option\",\"select\"],[[28,\"readonly\",[[23,5,[]]],null],[28,\"readonly\",[[23,3,[\"selected\"]]],null],[28,\"readonly\",[[23,3,[]]],null]]]],false],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"    \"],[7,\"span\",true],[10,\"class\",\"ember-power-select-selected-item\"],[8],[14,4,[[23,3,[\"selected\"]],[23,3,[]]]],[9],[0,\"\\n\"]],\"parameters\":[]}],[4,\"if\",[[28,\"and\",[[23,7,[]],[28,\"not\",[[23,3,[\"disabled\"]]],null]],null]],null,{\"statements\":[[0,\"    \"],[7,\"span\",false],[12,\"class\",\"ember-power-select-clear-btn\"],[3,\"on\",[\"mousedown\",[23,0,[\"clear\"]]]],[3,\"on\",[\"touchstart\",[23,0,[\"clear\"]]]],[8],[0,\"\xD7\"],[9],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[]},{\"statements\":[[0,\"  \"],[1,[28,\"component\",[[23,2,[]]],[[\"placeholder\"],[[23,1,[]]]]],false],[0,\"\\n\"]],\"parameters\":[]}],[5,\"fa-icon\",[],[[\"@class\",\"@icon\"],[\"dropdown-icon\",[28,\"if\",[[23,3,[\"isOpen\"]],\"angle-up\",\"angle-down\"],null]]],{\"statements\":[],\"parameters\":[]}]],\"hasEval\":false}",
    meta: {
      moduleName: "ds-ssdc/components/dropdown-trigger.hbs"
    }
  });

  class DropdownTriggerComponent extends _trigger.default {
    constructor() {
      super(...arguments);
    }

  }

  _exports.default = DropdownTriggerComponent;

  Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, DropdownTriggerComponent);
});