define("ds-ssdc/templates/dashboard", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "xKvAbLKi",
    "block": "{\"symbols\":[\"congress\"],\"statements\":[[7,\"div\",false],[12,\"class\",\"route-dashboard\"],[3,\"did-insert\",[[23,0,[\"initializeDashboard\"]]]],[8],[0,\"\\n\"],[4,\"if\",[[23,0,[\"congresses\"]]],null,{\"statements\":[[0,\"    \"],[5,\"power-select\",[],[[\"@triggerClass\",\"@options\",\"@onChange\",\"@selected\",\"@triggerComponent\"],[\"congress-switcher\",[23,0,[\"congresses\"]],[28,\"fn\",[[23,0,[\"routeTo\"]]],null],[23,0,[\"selectedCongress\"]],\"dropdown-trigger\"]],{\"statements\":[[0,\"\\n        \"],[1,[23,1,[\"name\"]],false],[0,\"\\n    \"]],\"parameters\":[1]}],[0,\"\\n\"]],\"parameters\":[]},null],[1,[22,\"outlet\"],false],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ds-ssdc/templates/dashboard.hbs"
    }
  });

  _exports.default = _default;
});