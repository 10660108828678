define("ds-ssdc/routes/index", ["exports", "ember-simple-auth/mixins/authenticated-route-mixin"], function (_exports, _authenticatedRouteMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class IndexRoute extends Ember.Route.extend(_authenticatedRouteMixin.default) {
    beforeModel() {
      this.transitionTo('dashboard');
    }

  }

  _exports.default = IndexRoute;
});