define("ds-ssdc/routes/sessions/session/slides-transcription", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class SessionsSessionSlidesTranscriptionRoute extends Ember.Route {
    model() {
      // console.log(this.modelFor('sessions.session').sessionId);
      return {
        sessionId: this.paramsFor('sessions.session').session_id
      };
    }

  }

  _exports.default = SessionsSessionSlidesTranscriptionRoute;
});