define("ds-ssdc/helpers/get-congress-url", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Helper.helper(function getCongressUrl(params
  /*, hash*/
  ) {
    if (params[0].substring(0, 4) == "http" || params[0].substring(0, 5) == "https") {
      if (params[1] == 1) {
        return params[0];
      } else {
        return params[0].split("//").slice(1).join("//");
      }
    } else {
      if (params[1] == 1) {
        return `https://${params[0]}`;
      } else {
        return params[0];
      }
    }
  });

  _exports.default = _default;
});