define("ds-ssdc/components/row-select", ["exports", "@glimmer/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _class;

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <Input @type='checkbox' 
      @checked={{@row.selected}}
      @click={{action "click"}}
      @doubleClick={{action "click"}}
      @disabled={{get @row column.disabledPath}} />
  */
  {
    id: "qDtn9hQC",
    block: "{\"symbols\":[\"@row\"],\"statements\":[[5,\"input\",[],[[\"@type\",\"@checked\",\"@click\",\"@doubleClick\",\"@disabled\"],[\"checkbox\",[23,1,[\"selected\"]],[28,\"action\",[[23,0,[]],\"click\"],null],[28,\"action\",[[23,0,[]],\"click\"],null],[28,\"get\",[[23,1,[]],[24,[\"column\",\"disabledPath\"]]],null]]]]],\"hasEval\":false}",
    meta: {
      moduleName: "ds-ssdc/components/row-select.hbs"
    }
  });

  let RowSelectComponent = (_dec = Ember._action, (_class = class RowSelectComponent extends _component.default {
    click(event) {
      event.stopPropagation();
    }

  }, (_applyDecoratedDescriptor(_class.prototype, "click", [_dec], Object.getOwnPropertyDescriptor(_class.prototype, "click"), _class.prototype)), _class));
  _exports.default = RowSelectComponent;

  Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, RowSelectComponent);
});