define("ds-ssdc/helpers/table-count", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Helper.helper(function tableCount(params) {
    const {
      page,
      limit,
      totalRecords
    } = params[0] || {};
    const start = page * limit + 1;
    let end = (page + 1) * limit;

    if (end > totalRecords) {
      end = totalRecords;
    }

    return {
      start,
      end,
      totalRecords: totalRecords
    };
  });

  _exports.default = _default;
});