define("ds-ssdc/components/array-row", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    {{#if (is-array @value)}}
      {{join ', ' @value}}
  {{else}}
      {{@value}}
  {{/if}}
  */
  {
    id: "hhZAYYA8",
    block: "{\"symbols\":[\"@value\"],\"statements\":[[4,\"if\",[[28,\"is-array\",[[23,1,[]]],null]],null,{\"statements\":[[0,\"    \"],[1,[28,\"join\",[\", \",[23,1,[]]],null],false],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"    \"],[1,[23,1,[]],false],[0,\"\\n\"]],\"parameters\":[]}]],\"hasEval\":false}",
    meta: {
      moduleName: "ds-ssdc/components/array-row.hbs"
    }
  });

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());

  _exports.default = _default;
});