define("ds-ssdc/helpers/session-status", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Helper.helper(function sessionStatus(params
  /*, hash*/
  ) {
    const session = params[0];

    switch (true) {
      case session.video_extraction_status === 'InProgress':
        return {
          "status": "Generating slide deck - Please wait"
        };

      case session.video_extraction_status === 'Done' && session.audio_extraction_status === 'New' && session.text_extraction_status === 'New' && session.transcription_status === 'New':
        return {
          "status": 'Unpublished',
          showVerificationPage: true
        };

      case session.video_extraction_status === 'Verified' && (session.audio_extraction_status === 'InProgress' || session.text_extraction_status === 'InProgress' || session.transcription_status === 'InProgress'):
        return {
          "status": 'Deck published, generating transcript',
          showVerificationPage: true
        };

      case session.video_extraction_status === 'Verified' && session.audio_extraction_status === 'Done' && session.text_extraction_status === 'Done' && session.transcription_status === 'Done':
        return {
          "status": 'Deck published, slide only',
          showTranscriptionPage: true
        };

      case session.video_extraction_status === 'Verified' && session.text_extraction_status === 'Verified' && session.transcription_status === 'Verified':
        return {
          "status": 'Decks published',
          showTranscriptionPage: true
        };

      default:
        return {
          "status": ""
        };
    }
  });

  _exports.default = _default;
});