define("ds-ssdc/templates/sessions/session", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "PD8wfuyL",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[11,\"class\",[29,[\"route-session \",[28,\"if\",[[28,\"eq\",[[23,0,[\"target\",\"currentRouteName\"]],\"sessions.session.slides-verification\"],null],\"verification-page\",\"\"],null]]]],[8],[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"session-details\"],[8],[0,\"\\n\"],[4,\"if\",[[23,0,[\"getSession\",\"isRunning\"]]],null,{\"statements\":[[0,\"            \"],[7,\"div\",true],[10,\"class\",\"session-details-loader\"],[8],[0,\"\\n                Loading session details...\\n            \"],[9],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"            \"],[7,\"div\",true],[10,\"class\",\"header header-large session-header\"],[8],[1,[23,0,[\"session\",\"title\"]],false],[9],[0,\"\\n            \"],[7,\"span\",true],[10,\"class\",\"session-category\"],[8],[1,[23,0,[\"session\",\"category\"]],false],[9],[0,\"\\n\"]],\"parameters\":[]}],[0,\"    \"],[9],[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"session-sub-routes\"],[8],[0,\"\\n        \"],[7,\"div\",true],[10,\"class\",\"navigation-tabs route-session-tabs\"],[8],[0,\"\\n            \"],[5,\"link-to\",[[12,\"class\",\"tab-wrapper tab-link\"]],[[\"@route\"],[\"sessions.session.slides-verification\"]],{\"statements\":[[0,\"\\n                \"],[7,\"div\",true],[10,\"class\",\"tab\"],[8],[0,\"\\n                    Slides\\n                \"],[9],[0,\"\\n            \"]],\"parameters\":[]}],[0,\"\\n            \"],[5,\"link-to\",[[12,\"class\",\"tab-wrapper tab-link\"]],[[\"@route\"],[\"sessions.session.slides-transcription\"]],{\"statements\":[[0,\"\\n                \"],[7,\"div\",true],[10,\"class\",\"tab\"],[8],[0,\"\\n                    Transcript\\n                \"],[9],[0,\"\\n            \"]],\"parameters\":[]}],[0,\"\\n        \"],[9],[0,\"\\n        \"],[1,[22,\"outlet\"],false],[0,\"\\n    \"],[9],[0,\"\\n\"],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ds-ssdc/templates/sessions/session.hbs"
    }
  });

  _exports.default = _default;
});