define("ds-ssdc/components/box-preview", ["exports", "@glimmer/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _class, _descriptor, _descriptor2, _temp;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <div class="box-preview-{{this.sessionId}}">
    <VideoPlayer
      @class="slides-verification-player"
      @src={{this.fileId}}
      @onError={{this.getBoxFileInfo}}
    />
  </div>
  */
  {
    id: "sORvCY1s",
    block: "{\"symbols\":[],\"statements\":[[7,\"div\",true],[11,\"class\",[29,[\"box-preview-\",[23,0,[\"sessionId\"]]]]],[8],[0,\"\\n  \"],[5,\"video-player\",[],[[\"@class\",\"@src\",\"@onError\"],[\"slides-verification-player\",[23,0,[\"fileId\"]],[23,0,[\"getBoxFileInfo\"]]]]],[0,\"\\n\"],[9]],\"hasEval\":false}",
    meta: {
      moduleName: "ds-ssdc/components/box-preview.hbs"
    }
  });

  /* global Box */
  let BoxPreviewComponent = (_dec = Ember.inject.service, _dec2 = Ember._tracked, _dec3 = Ember.computed("args.screenId"), _dec4 = Ember._action, (_class = (_temp = class BoxPreviewComponent extends _component.default {
    get sessionId() {
      this.getBoxFileInfo();
      return this.args.sessionId;
    }

    async getBoxFileInfo() {
      try {
        this.fileInfo = await this.ajax.request(`screens/get_video_info/${this.args.sessionId}`);
        this.fileId = this.fileInfo.video_url;
      } catch (error) {
        alert("Video File load error");
      }
    }

    constructor() {
      super(...arguments); //this.boxpreview = new Box.Preview()

      _defineProperty(this, "boxpreview", null);

      _defineProperty(this, "fileInfo", null);

      _initializerDefineProperty(this, "ajax", _descriptor, this);

      _initializerDefineProperty(this, "fileId", _descriptor2, this);
    }

  }, _temp), (_descriptor = _applyDecoratedDescriptor(_class.prototype, "ajax", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "fileId", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "sessionId", [_dec3], Object.getOwnPropertyDescriptor(_class.prototype, "sessionId"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "getBoxFileInfo", [_dec4], Object.getOwnPropertyDescriptor(_class.prototype, "getBoxFileInfo"), _class.prototype)), _class));
  _exports.default = BoxPreviewComponent;

  Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, BoxPreviewComponent);
});