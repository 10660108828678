define("ds-ssdc/components/fa-template", ["exports", "@glimmer/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    {{#with (get @sortIcons @sortIconProperty) as |sortIcon|}}
      {{#if (eq sortIcon 'sort')}}
      <span class="fa-stack fa-stack-vertical">
      <FaIcon @icon="angle-up" @class="fa-stack-vertical-top" />
      <FaIcon @icon="angle-down" @class="fa-stack-vertical-bottom" />
      </span>
      {{else}}
      <FaIcon @icon={{sortIcon}}></FaIcon>
      {{/if}}
  {{/with}}
  
  */
  {
    id: "z2ZcxCL9",
    block: "{\"symbols\":[\"sortIcon\",\"@sortIconProperty\",\"@sortIcons\"],\"statements\":[[4,\"with\",[[28,\"get\",[[23,3,[]],[23,2,[]]],null]],null,{\"statements\":[[4,\"if\",[[28,\"eq\",[[23,1,[]],\"sort\"],null]],null,{\"statements\":[[0,\"    \"],[7,\"span\",true],[10,\"class\",\"fa-stack fa-stack-vertical\"],[8],[0,\"\\n    \"],[5,\"fa-icon\",[],[[\"@icon\",\"@class\"],[\"angle-up\",\"fa-stack-vertical-top\"]]],[0,\"\\n    \"],[5,\"fa-icon\",[],[[\"@icon\",\"@class\"],[\"angle-down\",\"fa-stack-vertical-bottom\"]]],[0,\"\\n    \"],[9],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"    \"],[5,\"fa-icon\",[],[[\"@icon\"],[[23,1,[]]]],{\"statements\":[],\"parameters\":[]}],[0,\"\\n\"]],\"parameters\":[]}]],\"parameters\":[1]},null]],\"hasEval\":false}",
    meta: {
      moduleName: "ds-ssdc/components/fa-template.hbs"
    }
  });

  class FaTemplateComponent extends _component.default {
    constructor() {
      super(...arguments);
    }

  }

  _exports.default = FaTemplateComponent;

  Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, FaTemplateComponent);
});