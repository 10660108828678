define("ds-ssdc/components/smart-input", ["exports", "@glimmer/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _class, _descriptor, _temp;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <div class="smart-input">
      <span class="highlight-layer" {{ref this "$highlightLayer"}}>{{html-safe this.formattedValue}}</span>
      <Textarea
          @value={{@value}}
          @keyUp={{this.onChange}}
          @focusOut={{if this.edited (queue @onBlur (fn (mut this.edited) false)) (noop)}}
          {{ref this "$textArea"}}
          {{ref this.textFieldInserted}}
          {{did-update this.textFieldInserted @value}}
      />
  </div>
  */
  {
    id: "0b6WiTsv",
    block: "{\"symbols\":[\"@value\",\"@onBlur\"],\"statements\":[[7,\"div\",true],[10,\"class\",\"smart-input\"],[8],[0,\"\\n    \"],[7,\"span\",false],[12,\"class\",\"highlight-layer\"],[3,\"ref\",[[23,0,[]],\"$highlightLayer\"]],[8],[1,[28,\"html-safe\",[[23,0,[\"formattedValue\"]]],null],false],[9],[0,\"\\n    \"],[5,\"textarea\",[[3,\"ref\",[[23,0,[]],\"$textArea\"]],[3,\"ref\",[[23,0,[\"textFieldInserted\"]]]],[3,\"did-update\",[[23,0,[\"textFieldInserted\"]],[23,1,[]]]]],[[\"@value\",\"@keyUp\",\"@focusOut\"],[[23,1,[]],[23,0,[\"onChange\"]],[28,\"if\",[[23,0,[\"edited\"]],[28,\"queue\",[[23,2,[]],[28,\"fn\",[[28,\"mut\",[[23,0,[\"edited\"]]],null],false],null]],null],[28,\"noop\",null,null]],null]]]],[0,\"\"],[9]],\"hasEval\":false}",
    meta: {
      moduleName: "ds-ssdc/components/smart-input.hbs"
    }
  });

  function formatText(text = '', highlightText = '') {
    let escapedHighlightTxt = highlightText.replace(/[.*+\-?^${}()|[\]\\]/g, '\\$&');
    let regex = new RegExp(escapedHighlightTxt, 'gi');
    return text.replace(regex, match => `<mark>${match}</mark>`);
  }

  function htmlizeLineBreaks(text) {
    return text.replace(/\r?\n|\r/g, () => `<br/>`);
  }

  let SmartInputComponent = (_dec = Ember._tracked, _dec2 = Ember.computed('args.{value,highlightText}'), _dec3 = Ember._action, _dec4 = Ember._action, (_class = (_temp = class SmartInputComponent extends _component.default {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "edited", _descriptor, this);
    }

    get formattedValue() {
      if (this.args.highlightText) {
        let htmlText = formatText(this.args.value, this.args.highlightText);
        return htmlizeLineBreaks(htmlText);
      } else {
        return this.args.value;
      }
    }

    resizeTextarea() {
      setTimeout(() => {
        this.$textArea.style.height = 'auto';
        this.$textArea.style.height = `${this.$textArea.scrollHeight}px`;
      }, 0);
    }

    onChange() {
      if (!this.edited) {
        this.edited = true;
      }

      this.resizeTextarea();
    }

    textFieldInserted() {
      this.resizeTextarea();
    }

  }, _temp), (_descriptor = _applyDecoratedDescriptor(_class.prototype, "edited", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _applyDecoratedDescriptor(_class.prototype, "formattedValue", [_dec2], Object.getOwnPropertyDescriptor(_class.prototype, "formattedValue"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "onChange", [_dec3], Object.getOwnPropertyDescriptor(_class.prototype, "onChange"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "textFieldInserted", [_dec4], Object.getOwnPropertyDescriptor(_class.prototype, "textFieldInserted"), _class.prototype)), _class));
  _exports.default = SmartInputComponent;

  Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, SmartInputComponent);
});