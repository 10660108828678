define("ds-ssdc/templates/dashboard/congress/stats", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "OBzICnMS",
    "block": "{\"symbols\":[\"@model\"],\"statements\":[[7,\"div\",true],[10,\"class\",\"session-section\"],[8],[0,\"\\n    \"],[7,\"h3\",true],[8],[0,\"Sessions\"],[9],[0,\"\\n\"],[4,\"if\",[[23,1,[\"congressId\"]]],null,{\"statements\":[[0,\"     \"],[5,\"paginated-table\",[],[[\"@emptyMessage\",\"@includeModel\",\"@reloadTable\",\"@includeData\",\"@csvFileName\",\"@fetchUrl\",\"@recordsPath\",\"@sort\",\"@sortType\",\"@setTableReference\",\"@onRowClick\",\"@columns\"],[\"No Records found.\",true,[28,\"or\",[[23,0,[\"model\",\"congressId\"]],[23,0,[\"refreshTable\"]]],null],[28,\"hash\",null,[[\"congress_id\"],[[23,1,[\"congressId\"]]]]],\"contents\",\"/status/view\",\"sessions\",\"ferma_id\",\"asc\",[23,0,[\"setTableReference\"]],[23,0,[\"noOp\"]],[23,0,[\"statsColumns\"]]]]],[0,\"\\n\"]],\"parameters\":[]},null],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ds-ssdc/templates/dashboard/congress/stats.hbs"
    }
  });

  _exports.default = _default;
});