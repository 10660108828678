define("ds-ssdc/components/column-select-all", ["exports", "ember-light-table/components/columns/base", "@ember-decorators/object"], function (_exports, _base, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _class, _descriptor, _descriptor2, _temp;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <Input @type='checkbox'
      @checked={{this.isChecked}}
      @change={{action "toggleSelection"}}
      @disabled={{get @row column.disabledPath}}
      @indeterminate={{this.isIndeterminate}} />
  */
  {
    id: "vy7OKjZg",
    block: "{\"symbols\":[\"@row\"],\"statements\":[[5,\"input\",[],[[\"@type\",\"@checked\",\"@change\",\"@disabled\",\"@indeterminate\"],[\"checkbox\",[23,0,[\"isChecked\"]],[28,\"action\",[[23,0,[]],\"toggleSelection\"],null],[28,\"get\",[[23,1,[]],[24,[\"column\",\"disabledPath\"]]],null],[23,0,[\"isIndeterminate\"]]]]]],\"hasEval\":false}",
    meta: {
      moduleName: "ds-ssdc/components/column-select-all.hbs"
    }
  });
  /* eslint-disable ember/no-observers */


  let ColumnSelectAllComponent = (_dec = Ember._tracked, _dec2 = Ember._tracked, _dec3 = (0, _object.observes)("table.rows.[]", "table.selectedRows.[]"), _dec4 = Ember._action, (_class = (_temp = class ColumnSelectAllComponent extends _base.default {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "isChecked", _descriptor, this);

      _initializerDefineProperty(this, "isIndeterminate", _descriptor2, this);
    }

    listenforValues() {
      const rows = this.table.rows.length;
      const selectedRows = this.table.selectedRows.length;
      this.isChecked = rows === selectedRows && rows;
      this.isIndeterminate = rows !== selectedRows && selectedRows;
    }

    selectAll() {
      this.isChecked = true;
      this.table.rows.setEach('selected', true);
    }

    deselectAll() {
      this.isChecked = false;
      this.table.selectedRows.setEach('selected', false);
    }

    toggleSelection() {
      if (this.isChecked) {
        this.deselectAll();
      } else {
        this.selectAll();
      }
    }

  }, _temp), (_descriptor = _applyDecoratedDescriptor(_class.prototype, "isChecked", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "isIndeterminate", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _applyDecoratedDescriptor(_class.prototype, "listenforValues", [_dec3], Object.getOwnPropertyDescriptor(_class.prototype, "listenforValues"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "toggleSelection", [_dec4], Object.getOwnPropertyDescriptor(_class.prototype, "toggleSelection"), _class.prototype)), _class));
  _exports.default = ColumnSelectAllComponent;

  Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, ColumnSelectAllComponent);
});