define("ds-ssdc/controllers/dashboard/congress/content", ["exports", "moment"], function (_exports, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _dec11, _dec12, _dec13, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _temp;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let DashboardCongressContentController = (_dec = Ember.inject.controller('dashboard.congress'), _dec2 = Ember.inject.service, _dec3 = Ember._tracked, _dec4 = Ember._tracked, _dec5 = Ember._tracked, _dec6 = Ember.computed('congressController.selectedTenant'), _dec7 = Ember.computed('congressController.tenants.[]'), _dec8 = Ember.computed('congressController.dateRange.{before,after}'), _dec9 = Ember._tracked, _dec10 = Ember._tracked, _dec11 = Ember._action, _dec12 = Ember._action, _dec13 = Ember._action, (_class = (_temp = class DashboardCongressContentController extends Ember.Controller {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "congressController", _descriptor, this);

      _initializerDefineProperty(this, "ajax", _descriptor2, this);

      _defineProperty(this, "contentsTable", null);

      _initializerDefineProperty(this, "showModel", _descriptor3, this);

      _initializerDefineProperty(this, "refreshTable", _descriptor4, this);

      _initializerDefineProperty(this, "showStatus", _descriptor5, this);

      _initializerDefineProperty(this, "statColumns", _descriptor6, this);

      _initializerDefineProperty(this, "contentColumns", _descriptor7, this);
    }

    // eslint-disable-next-line ember/no-observers
    get tenantLogic() {
      const teamIndex = this.contentColumns.findIndex(item => item.label === 'Team');

      if (this.congressController.selectedTenant && this.congressController.selectedTenant.id === 'common') {
        Ember.set(this, 'contentColumns.' + teamIndex + '.hidden', true);
      } else {
        Ember.set(this, 'contentColumns.' + teamIndex + '.hidden', false);
      }

      return "";
    }

    get tenants() {
      const tenantIndex = this.contentColumns.findIndex(item => item.label === 'SSD Tenant');
      Ember.set(this, 'contentColumns.' + tenantIndex + '.searchOptions', this.congressController.tenants);
      return "";
    }

    get dateRange() {
      if (this.congressController.dateRange.after && this.congressController.dateRange.before) {
        return (0, _moment.default)(this.congressController.dateRange.after).format('YYYY-MM-DD') + " - " + (0, _moment.default)(this.congressController.dateRange.before).format('YYYY-MM-DD');
      }

      return "";
    }

    async setColumnFilters() {
      try {
        const data = await this.ajax.request('contents/filters', {
          method: "POST",
          data: {
            "congress_id": this.model.congressId
          },
          contentType: "application/json;charset=utf-8",
          dataType: "json"
        });

        for (const key in data) {
          const typeIndex = this.contentColumns.findIndex(pc => pc.valuePath === key);

          if (typeIndex > -1) {
            const searchOptions = [];

            for (const value in data[key]) {
              searchOptions.push({
                id: value,
                name: data[key][value]
              });
            }

            this.set(`contentColumns.${typeIndex}.searchType`, 'select');
            this.set(`contentColumns.${typeIndex}.searchOptions`, searchOptions);
          }
        }
      } catch (error) {
        throw error;
      }
    }

    setTableReference(contentsTable) {
      this.contentsTable = contentsTable;
      this.setColumnFilters();
    }

    async modifyContent(action) {
      const selectedIds = this.contentsTable.selectedRows.map(r => r.content.ferma_id);

      if (!selectedIds.length && action != 'download') {
        alert("No ids selected");
        return;
      }

      switch (action) {
        case 'download':
          try {
            const {
              xhr
            } = this.ajax.request('contents/info/download', {
              method: "POST",
              processData: false,
              data: {
                congress_id: this.model.congressId,
                tenant_id: this.congressController.selectedTenant.id
              },
              dataType: 'binary',
              xhrFields: {
                responseType: 'blob'
              }
            });
            const data = await xhr.promise();
            let url = window.URL.createObjectURL(new Blob([data], {
              type: "text/csv"
            }));
            let a = document.createElement('a');
            a.href = url;
            a.download = 'contents.csv';
            document.body.append(a);
            a.click();
            a.remove();
            window.URL.revokeObjectURL(url);
          } catch (error) {
            alert("Couldn't download sessions");
          }

          break;

        case 'delete':
          try {
            const payload = {
              "ferma_session_ids": selectedIds
            };
            const teamIds = this.contentsTable.selectedRows.map(r => r.content.team_id).uniq();

            if (teamIds.length == 1 && teamIds[0]) {
              payload["team_id"] = teamIds[0];
            }

            if (this.congressController.selectedTenant) {
              payload["tenant_id"] = this.congressController.selectedTenant.id;
            }

            if (this.congressController.model.id) {
              payload["congress_id"] = this.congressController.model.id;
            }

            await this.ajax.request('contents/delete', {
              method: "POST",
              data: payload,
              contentType: "application/json;charset=utf-8",
              dataType: "json"
            });
            this.incrementProperty('refreshTable');
            alert("Content deleted successfully");
          } catch (error) {
            alert("Couldn't delete contents");
          }

          break;
      }
    }

    noOp() {}

  }, _temp), (_descriptor = _applyDecoratedDescriptor(_class.prototype, "congressController", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "ajax", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "showModel", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "refreshTable", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return 0;
    }
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "showStatus", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return true;
    }
  }), _applyDecoratedDescriptor(_class.prototype, "tenantLogic", [_dec6], Object.getOwnPropertyDescriptor(_class.prototype, "tenantLogic"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "tenants", [_dec7], Object.getOwnPropertyDescriptor(_class.prototype, "tenants"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "dateRange", [_dec8], Object.getOwnPropertyDescriptor(_class.prototype, "dateRange"), _class.prototype), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "statColumns", [_dec9], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return Ember.A([{
        label: "Priority",
        width: "120px",
        valuePath: "Priority",
        sortable: false,
        searchable: false
      }, {
        label: "TBU",
        width: "120px",
        valuePath: "TBU",
        sortable: false,
        searchable: false
      }, {
        label: "In Progress",
        width: "120px",
        valuePath: "In Progress",
        sortable: false,
        searchable: false
      }, {
        label: "Processed",
        width: "120px",
        valuePath: "Processed",
        sortable: false,
        searchable: false
      }, {
        label: "Failed",
        width: "120px",
        valuePath: "Failed",
        sortable: false,
        searchable: false
      }, {
        label: "Partial",
        width: "120px",
        valuePath: "Partial",
        sortable: false,
        searchable: false
      }]);
    }
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "contentColumns", [_dec10], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return Ember.A([{
        label: "Select ",
        width: "40px",
        valuePath: "",
        component: 'column-select-all',
        cellComponent: 'row-select',
        sortable: false,
        searchable: false
      }, {
        label: "Ferma Id",
        width: "120px",
        valuePath: "ferma_id",
        sortable: true
      }, {
        label: "Abs Id",
        width: "120px",
        valuePath: "abs_id",
        sortable: true
      }, {
        label: "Title",
        width: "120px",
        valuePath: "title",
        sortable: true
      }, {
        label: "Session Date",
        width: "120px",
        valuePath: "session_date",
        cellComponent: "date-row",
        sortable: true
      }, {
        label: "Type",
        width: "120px",
        valuePath: "type",
        sortable: true
      }, {
        label: "Team",
        width: "120px",
        valuePath: "team_name",
        sortable: true
      }, {
        label: "SSD Tenant",
        width: "120px",
        dataType: 'enumeration',
        searchType: 'select',
        valuePath: "ssd_tenant",
        cellClassNames: 'break-word',
        cellComponent: "ssd-tenants",
        searchOptions: this.congressController.tenants,
        sortable: true
      }, {
        label: "Target",
        width: "120px",
        valuePath: "target",
        sortable: true
      }, {
        label: "Status",
        width: "120px",
        valuePath: "status",
        sortable: true
      }]);
    }
  }), _applyDecoratedDescriptor(_class.prototype, "setTableReference", [_dec11], Object.getOwnPropertyDescriptor(_class.prototype, "setTableReference"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "modifyContent", [_dec12], Object.getOwnPropertyDescriptor(_class.prototype, "modifyContent"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "noOp", [_dec13], Object.getOwnPropertyDescriptor(_class.prototype, "noOp"), _class.prototype)), _class));
  _exports.default = DashboardCongressContentController;
});