define("ds-ssdc/components/video-player", ["exports", "@glimmer/component", "plyr"], function (_exports, _component, _plyr) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _temp;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <div class="video-player">
  
    <video
      id="player"
      {{did-insert this.initializePlayer}}
      {{did-update this.updateSource @src}}
    >
      <source />
    </video>
  </div>
  */
  {
    id: "4UGWKCfV",
    block: "{\"symbols\":[\"@src\"],\"statements\":[[7,\"div\",true],[10,\"class\",\"video-player\"],[8],[0,\"\\n\\n  \"],[7,\"video\",false],[12,\"id\",\"player\"],[3,\"did-insert\",[[23,0,[\"initializePlayer\"]]]],[3,\"did-update\",[[23,0,[\"updateSource\"]],[23,1,[]]]],[8],[0,\"\\n    \"],[7,\"source\",true],[8],[9],[0,\"\\n  \"],[9],[0,\"\\n\"],[9]],\"hasEval\":false}",
    meta: {
      moduleName: "ds-ssdc/components/video-player.hbs"
    }
  });

  let VideoPlayerComponent = (_dec = Ember._tracked, _dec2 = Ember._tracked, _dec3 = Ember._tracked, _dec4 = Ember._tracked, _dec5 = Ember._action, _dec6 = Ember._action, _dec7 = Ember._action, (_class = (_temp = class VideoPlayerComponent extends _component.default {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "src", _descriptor, this);

      _initializerDefineProperty(this, "defaultControls", _descriptor2, this);

      _initializerDefineProperty(this, "stalledAt", _descriptor3, this);

      _initializerDefineProperty(this, "player", _descriptor4, this);
    }

    initializePlayer(element) {
      const options = {
        controls: ["play-large", "play", "progress", "current-time", "duration", "mute", "volume", "captions", "settings", "pip", "airplay", "fullscreen"],
        seekTime: 5,
        keyboard: {
          global: true
        }
      };
      this.player = new _plyr.default(element, options);
      this.updateSource();
      this.player.on("stalled", this.onVideoStalled);
    }

    async updateSource() {
      if (this.args.src) {
        const source = {
          type: "video",
          sources: [{
            src: this.args.src,
            type: "video/mp4"
          }]
        };
        this.player.source = source;
      }

      if (this.stalledAt) {
        await this.player.play();
        this.player.forward(this.stalledAt);
        this.stalledAt = null;
      }
    }

    async onVideoStalled(e) {
      if (this.args.onError) {
        this.stalledAt = this.player.currentTime;
        await this.args.onError();
      }
    }

  }, _temp), (_descriptor = _applyDecoratedDescriptor(_class.prototype, "src", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "defaultControls", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return true;
    }
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "stalledAt", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return null;
    }
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "player", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "initializePlayer", [_dec5], Object.getOwnPropertyDescriptor(_class.prototype, "initializePlayer"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "updateSource", [_dec6], Object.getOwnPropertyDescriptor(_class.prototype, "updateSource"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "onVideoStalled", [_dec7], Object.getOwnPropertyDescriptor(_class.prototype, "onVideoStalled"), _class.prototype)), _class));
  _exports.default = VideoPlayerComponent;

  Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, VideoPlayerComponent);
});