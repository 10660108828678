define("ds-ssdc/router", ["exports", "ds-ssdc/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  class Router extends Ember.Router {
    constructor(...args) {
      super(...args);

      _defineProperty(this, "location", _environment.default.locationType);

      _defineProperty(this, "rootURL", _environment.default.rootURL);
    }

  }

  _exports.default = Router;
  Router.map(function () {
    this.route('login');
    this.route('dashboard', function () {
      this.route('congress', {
        path: '/:congress_id'
      }, function () {
        this.route('webcasts');
        this.route('content');
        this.route('stats');
      });
    });
    this.route('sessions', function () {
      this.route('session', {
        path: '/:session_id'
      }, function () {
        this.route('slides-verification');
        this.route('slides-transcription');
      });
    });
  });
});