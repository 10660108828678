define("ds-ssdc/components/videojs-base", ["exports", "ember-videojs/components/videojs-base"], function (_exports, _videojsBase) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function () {
      return _videojsBase.default;
    }
  });
});