define("ds-ssdc/components/row-webcast-download", ["exports", "@glimmer/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _temp;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    {{#if (eq @row.ssd_status "Generated")}}
  <PowerSelect 
      @tagName="div"
      @allowClear={{false}}
      @triggerClass='doc-download-switcher'
      @options={{array "SSD" "pdf" "docx"}}
      @selected={{"SSD"}}
      @onChange={{fn this.downloadFile "sessions/download_ssd_pdf" @row.deck_id "SSD"}}
      @triggerComponent="dropdown-trigger"
      as |option|>
      {{option}}
  </PowerSelect>
  {{else if (not-eq @row.ssd_status "TBG")}}
      <div class="{{camelize @row.ssd_status}}" title="{{@row.ssd_status}}">SSD</div>
  {{/if}}
  &nbsp;
  {{#if (eq @row.sod_status "Generated")}}
  <PowerSelect 
      @tagName="div"
      @allowClear={{false}}
      @triggerClass='doc-download-switcher'
      @options={{array "SOD" "pdf" "docx"}}
      @selected={{"SOD"}}
      @onChange={{fn this.downloadFile "sessions/download_ssd_pdf" @row.deck_id "SOD"}}
      @triggerComponent="dropdown-trigger"
      as |option|>
      {{option}}
  </PowerSelect>
  {{else if (not-eq @row.sod_status "TBG")}}
      <div class="{{camelize @row.sod_status}}" title="{{@row.sod_status}}">SOD</div>
  {{/if}}
  
  */
  {
    id: "j/oaG2sO",
    block: "{\"symbols\":[\"option\",\"option\",\"@row\"],\"statements\":[[4,\"if\",[[28,\"eq\",[[23,3,[\"ssd_status\"]],\"Generated\"],null]],null,{\"statements\":[[5,\"power-select\",[],[[\"@tagName\",\"@allowClear\",\"@triggerClass\",\"@options\",\"@selected\",\"@onChange\",\"@triggerComponent\"],[\"div\",false,\"doc-download-switcher\",[28,\"array\",[\"SSD\",\"pdf\",\"docx\"],null],\"SSD\",[28,\"fn\",[[23,0,[\"downloadFile\"]],\"sessions/download_ssd_pdf\",[23,3,[\"deck_id\"]],\"SSD\"],null],\"dropdown-trigger\"]],{\"statements\":[[0,\"\\n    \"],[1,[23,2,[]],false],[0,\"\\n\"]],\"parameters\":[2]}],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[4,\"if\",[[28,\"not-eq\",[[23,3,[\"ssd_status\"]],\"TBG\"],null]],null,{\"statements\":[[0,\"    \"],[7,\"div\",true],[11,\"class\",[29,[[28,\"camelize\",[[23,3,[\"ssd_status\"]]],null]]]],[11,\"title\",[29,[[23,3,[\"ssd_status\"]]]]],[8],[0,\"SSD\"],[9],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[]}],[0,\"\xA0\\n\"],[4,\"if\",[[28,\"eq\",[[23,3,[\"sod_status\"]],\"Generated\"],null]],null,{\"statements\":[[5,\"power-select\",[],[[\"@tagName\",\"@allowClear\",\"@triggerClass\",\"@options\",\"@selected\",\"@onChange\",\"@triggerComponent\"],[\"div\",false,\"doc-download-switcher\",[28,\"array\",[\"SOD\",\"pdf\",\"docx\"],null],\"SOD\",[28,\"fn\",[[23,0,[\"downloadFile\"]],\"sessions/download_ssd_pdf\",[23,3,[\"deck_id\"]],\"SOD\"],null],\"dropdown-trigger\"]],{\"statements\":[[0,\"\\n    \"],[1,[23,1,[]],false],[0,\"\\n\"]],\"parameters\":[1]}],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[4,\"if\",[[28,\"not-eq\",[[23,3,[\"sod_status\"]],\"TBG\"],null]],null,{\"statements\":[[0,\"    \"],[7,\"div\",true],[11,\"class\",[29,[[28,\"camelize\",[[23,3,[\"sod_status\"]]],null]]]],[11,\"title\",[29,[[23,3,[\"sod_status\"]]]]],[8],[0,\"SOD\"],[9],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[]}]],\"hasEval\":false}",
    meta: {
      moduleName: "ds-ssdc/components/row-webcast-download.hbs"
    }
  });

  let RowWebcastDownloadComponent = (_dec = Ember.inject.service, _dec2 = Ember._tracked, _dec3 = Ember._tracked, _dec4 = Ember._tracked, _dec5 = Ember._tracked, _dec6 = Ember._action, (_class = (_temp = class RowWebcastDownloadComponent extends _component.default {
    constructor() {
      super(...arguments);

      _initializerDefineProperty(this, "ajax", _descriptor, this);

      _initializerDefineProperty(this, "value", _descriptor2, this);

      _initializerDefineProperty(this, "column", _descriptor3, this);

      _initializerDefineProperty(this, "row", _descriptor4, this);

      _initializerDefineProperty(this, "downloadOptions", _descriptor5, this);

      this.value = this.args.value;
      this.row = this.args.row;
      this.column = this.args.column;
    }

    async downloadFile(api_url, sessionId, pdf_type, option) {
      if (option === "SSD" || option === "SOD") return;
      window.event && window.event.preventDefault();
      const {
        xhr
      } = this.ajax.request(`${api_url}/${sessionId}`, {
        processData: false,
        type: "POST",
        data: {
          download_type: option,
          ssd: pdf_type === "SSD"
        },
        dataType: 'binary',
        xhrFields: {
          responseType: 'blob'
        }
      });
      const data = await xhr.promise();
      let contentDispositionHeader = xhr.getResponseHeader('Content-Disposition');
      let regex = new RegExp('[^=]+$');
      let filename = contentDispositionHeader && contentDispositionHeader.match(regex) && contentDispositionHeader.match(regex)[0] || sessionId;
      let url = window.URL.createObjectURL(new Blob([data], {
        type: "application/pdf"
      }));
      let a = document.createElement('a');
      a.href = url;
      a.download = filename;
      document.body.append(a);
      a.click();
      a.remove();
      window.URL.revokeObjectURL(url);
    }

  }, _temp), (_descriptor = _applyDecoratedDescriptor(_class.prototype, "ajax", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "value", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "column", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "row", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "downloadOptions", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return ['doc', 'pdf'];
    }
  }), _applyDecoratedDescriptor(_class.prototype, "downloadFile", [_dec6], Object.getOwnPropertyDescriptor(_class.prototype, "downloadFile"), _class.prototype)), _class));
  _exports.default = RowWebcastDownloadComponent;

  Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, RowWebcastDownloadComponent);
});