define("ds-ssdc/components/toggle-switch", ["exports", "@glimmer/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _class, _descriptor, _temp;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <label class="switch">
    <Input @type='checkbox' @checked={{this.value}} @change={{action @onchange}} />
    <span class="slider round"></span>
  </label>
  */
  {
    id: "1GODCa7/",
    block: "{\"symbols\":[\"@onchange\"],\"statements\":[[7,\"label\",true],[10,\"class\",\"switch\"],[8],[0,\"\\n  \"],[5,\"input\",[],[[\"@type\",\"@checked\",\"@change\"],[\"checkbox\",[23,0,[\"value\"]],[28,\"action\",[[23,0,[]],[23,1,[]]],null]]]],[0,\"\\n  \"],[7,\"span\",true],[10,\"class\",\"slider round\"],[8],[9],[0,\"\\n\"],[9]],\"hasEval\":false}",
    meta: {
      moduleName: "ds-ssdc/components/toggle-switch.hbs"
    }
  });

  let ToggleSwitchComponent = (_dec = Ember._tracked, (_class = (_temp = class ToggleSwitchComponent extends _component.default {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "value", _descriptor, this);
    }

  }, _temp), (_descriptor = _applyDecoratedDescriptor(_class.prototype, "value", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class));
  _exports.default = ToggleSwitchComponent;

  Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, ToggleSwitchComponent);
});