define("ds-ssdc/templates/application", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "FB3U3y4J",
    "block": "{\"symbols\":[],\"statements\":[[4,\"if\",[[23,0,[\"session\",\"isAuthenticated\"]]],null,{\"statements\":[[0,\"  \"],[7,\"header\",true],[8],[0,\"\\n    \"],[7,\"a\",true],[10,\"href\",\"/dashboard/\"],[8],[0,\"\\n      \"],[7,\"div\",true],[10,\"class\",\"logo\"],[8],[0,\"\\n        \"],[7,\"picture\",true],[8],[0,\"\\n          \"],[7,\"source\",true],[10,\"srcset\",\"/images/f.png\"],[10,\"sizes\",\"1x\"],[10,\"type\",\"image/png\"],[8],[9],[0,\"\\n          \"],[7,\"source\",true],[10,\"srcset\",\"/images/f@2x.png\"],[10,\"sizes\",\"2x\"],[10,\"type\",\"image/png\"],[8],[9],[0,\"\\n          \"],[7,\"source\",true],[10,\"srcset\",\"/images/f@3x.png\"],[10,\"sizes\",\"3x\"],[10,\"type\",\"image/png\"],[8],[9],[0,\"\\n          \"],[7,\"img\",true],[10,\"src\",\"/images/f.png\"],[10,\"alt\",\"Ferma\"],[8],[9],[0,\"\\n        \"],[9],[0,\"\\n      \"],[9],[0,\"\\n    \"],[9],[0,\"\\n    \"],[7,\"h2\",true],[8],[0,\"Smart Session Deck Creator\"],[9],[0,\"\\n    \"],[7,\"div\",false],[12,\"class\",\"logout-button\"],[12,\"title\",\"Sign Out\"],[12,\"role\",\"button\"],[3,\"on\",[\"click\",[23,0,[\"onLogout\"]]]],[8],[0,\"\\n        \"],[7,\"img\",true],[10,\"src\",\"/images/sign-out.png\"],[10,\"alt\",\"sign out\"],[8],[9],[0,\"\\n    \"],[9],[0,\"\\n  \"],[9],[0,\"\\n\"]],\"parameters\":[]},null],[1,[22,\"outlet\"],false]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ds-ssdc/templates/application.hbs"
    }
  });

  _exports.default = _default;
});