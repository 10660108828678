define("ds-ssdc/routes/dashboard/congress/stats", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class DashboardCongressStatsRoute extends Ember.Route {}

  _exports.default = DashboardCongressStatsRoute;
});