define("ds-ssdc/helpers/pagination", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Helper.helper(function pagination(params) {
    let currentPage = params[0],
        totalPage = params[1],
        size = params[2] || 10;
    var startPage, endPage, context;
    startPage = currentPage - Math.floor(size / 2);
    endPage = currentPage + Math.floor(size / 2);

    if (startPage <= 0) {
      endPage -= startPage - 1;
      startPage = 0;
    }

    if (endPage > totalPage) {
      endPage = totalPage;

      if (endPage - size + 1 > 0) {
        startPage = endPage - size + 1;
      } else {
        startPage = 0;
      }
    }

    context = {
      startFromFirstPage: false,
      pages: [],
      endAtLastPage: false
    };

    if (startPage === 0) {
      context.startFromFirstPage = true;
    }

    for (var i = startPage; i < endPage; i++) {
      context.pages.push({
        page: i,
        isCurrent: i === currentPage
      });
    }

    if (endPage === totalPage) {
      context.endAtLastPage = true;
    }

    return context;
  });

  _exports.default = _default;
});