define("ds-ssdc/controllers/sessions/session/slides-transcription", ["exports", "ember-concurrency", "@ember-decorators/object"], function (_exports, _emberConcurrency, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _dec11, _dec12, _dec13, _dec14, _dec15, _dec16, _dec17, _dec18, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8, _descriptor9, _descriptor10, _temp;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  const TRANSCRIPT_EXTRACTION_STATUS = {
    NEW: 'New',
    IN_PROGRESS: 'In Progress',
    DONE: 'Done',
    FAILED: 'Failed',
    VERIFIED: 'Verified'
  };
  let SessionsSessionSlidesTranscriptionController = (_dec = Ember.inject.service, _dec2 = Ember._tracked, _dec3 = Ember._tracked, _dec4 = Ember._tracked, _dec5 = Ember._tracked, _dec6 = Ember.computed('slides.[]'), _dec7 = Ember.computed('getSlides.last.value'), _dec8 = Ember.computed('getSession.last.value'), _dec9 = (0, _emberConcurrency.task)(function* () {
    try {
      return yield this.ajax.request(`/sessions/${this.model.sessionId}`);
    } catch (error) {// TODO: Handle error for this web service
      // console.log(error);
    }
  }), _dec10 = (0, _emberConcurrency.task)(function* () {
    try {
      return yield this.ajax.request(`/slides/`, {
        data: {
          session_id: this.model.sessionId
        }
      });
    } catch (error) {// TODO: Handle error for this web service
      // console.log(error);
    }
  }), _dec11 = (0, _object.observes)('model.sessionId'), _dec12 = (0, _emberConcurrency.task)(function* (slide, index) {
    try {
      const response = yield this.ajax.request(`/slides/${slide.id}`, {
        type: 'PATCH',
        data: slide
      });
      this.highlightSlide("success", index);
      return response;
    } catch (error) {
      this.highlightSlide("failure", index); // TODO: Handle error for this web service
      // console.log(error);
    }
  }), _dec13 = (0, _emberConcurrency.task)(function* () {
    try {
      return yield this.ajax.request(`/slides/findAndReplace`, {
        type: 'PATCH',
        data: {
          session_id: this.model.sessionId,
          find_text: this.transcriptSearchText,
          replace_text: this.transcriptReplaceText
        }
      });
    } catch (error) {// TODO: Handle error for this web service
      // console.log(error);
    }
  }), _dec14 = (0, _emberConcurrency.task)(function* (fields) {
    try {
      return yield this.ajax.request(`/sessions/${this.model.sessionId}`, {
        type: 'PATCH',
        data: fields
      });
    } catch (error) {// TODO: Handle error for this web service
      // console.log(error);
    }
  }), _dec15 = Ember._action, _dec16 = Ember._action, _dec17 = Ember._action, _dec18 = Ember._action, (_class = (_temp = class SessionsSessionSlidesTranscriptionController extends Ember.Controller {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "ajax", _descriptor, this);

      _initializerDefineProperty(this, "transcriptSearchText", _descriptor2, this);

      _initializerDefineProperty(this, "transcriptReplaceText", _descriptor3, this);

      _initializerDefineProperty(this, "newSlideInsertAfter", _descriptor4, this);

      _initializerDefineProperty(this, "refreshSlides", _descriptor5, this);

      _initializerDefineProperty(this, "getSession", _descriptor6, this);

      _initializerDefineProperty(this, "getSlides", _descriptor7, this);

      _initializerDefineProperty(this, "saveSlide", _descriptor8, this);

      _initializerDefineProperty(this, "findAndReplace", _descriptor9, this);

      _initializerDefineProperty(this, "updateSession", _descriptor10, this);
    }

    // @tracked newSlideEndTime = ''
    get selectedSlide() {
      return this.slides[0];
    }

    get slides() {
      // console.log("computing ", this.getSession.last)
      if (this.getSlides.last && this.getSlides.last.value) {
        return this.getSlides.last.value.slides;
      }

      return [];
    }

    get session() {
      // console.log("computing ", this.getSession.last)
      if (this.getSession.last && this.getSession.last.value) {
        return this.getSession.last.value;
      }

      return {};
    }

    // eslint-disable-next-line ember/no-observers
    getSlidesData() {
      // console.log(this.model);
      this.getSession.perform();
      this.getSlides.perform();
    }

    highlightSlide(status, index) {
      const slideNum = index >= 0 ? index + 1 : 0;
      const className = "js-slide-transcription";
      const timer = 600;

      if (slideNum) {
        let slideElement = document.querySelector(`.${className}-${slideNum}`);
        slideElement.classList.add(`${className}--${status}`);
        setTimeout(() => {
          slideElement.classList.remove(`${className}--${status}`);
        }, timer);
      }
    }

    slideEdited(slide, index) {
      this.saveSlide.perform(slide, index);
    }

    replaceAll() {
      if (this.transcriptSearchText.trim() && this.transcriptReplaceText.trim()) {
        this.refreshSlides = true;
        this.findAndReplace.perform().then(() => {
          this.getSlides.perform().then(() => {
            this.refreshSlides = false;
          }).catch(() => {
            if (confirm('The data may be obsolete. Click ok to refresh the page.')) {
              window.location.reload();
            }
          });
        });
      }
    }

    publish() {
      this.updateSession.perform({
        transcription_status: TRANSCRIPT_EXTRACTION_STATUS.VERIFIED,
        text_extraction_status: TRANSCRIPT_EXTRACTION_STATUS.VERIFIED
      }).then(data => {
        if (data.length && data['congress_id']) {
          alert('Session published successfully');
          this.transitionToRoute('dashboard.congress.webcasts', data['congress_id']);
        } else {
          this.transitionToRoute('dashboard');
        }
      });
    }

  }, _temp), (_descriptor = _applyDecoratedDescriptor(_class.prototype, "ajax", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "transcriptSearchText", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return '';
    }
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "transcriptReplaceText", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return '';
    }
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "newSlideInsertAfter", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return null;
    }
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "refreshSlides", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _applyDecoratedDescriptor(_class.prototype, "selectedSlide", [_dec6], Object.getOwnPropertyDescriptor(_class.prototype, "selectedSlide"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "slides", [_dec7], Object.getOwnPropertyDescriptor(_class.prototype, "slides"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "session", [_dec8], Object.getOwnPropertyDescriptor(_class.prototype, "session"), _class.prototype), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "getSession", [_dec9], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "getSlides", [_dec10], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "getSlidesData", [_dec11], Object.getOwnPropertyDescriptor(_class.prototype, "getSlidesData"), _class.prototype), _descriptor8 = _applyDecoratedDescriptor(_class.prototype, "saveSlide", [_dec12], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor9 = _applyDecoratedDescriptor(_class.prototype, "findAndReplace", [_dec13], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor10 = _applyDecoratedDescriptor(_class.prototype, "updateSession", [_dec14], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "highlightSlide", [_dec15], Object.getOwnPropertyDescriptor(_class.prototype, "highlightSlide"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "slideEdited", [_dec16], Object.getOwnPropertyDescriptor(_class.prototype, "slideEdited"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "replaceAll", [_dec17], Object.getOwnPropertyDescriptor(_class.prototype, "replaceAll"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "publish", [_dec18], Object.getOwnPropertyDescriptor(_class.prototype, "publish"), _class.prototype)), _class));
  _exports.default = SessionsSessionSlidesTranscriptionController;
});