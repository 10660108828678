define("ds-ssdc/helpers/asset-url", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Helper.helper(function assetUrl(params
  /*, hash*/
  ) {
    let relativePath = params[0];
    let context = params[1];
    let timeStamp = params[2];
    let url;

    if (context) {
      url = `${context.ajax.host}${relativePath}?jwt=${context.get('ajax.session.data.authenticated.token')}`;
      url += timeStamp ? `&t=${timeStamp}` : ``;
    } else {
      url = relativePath;
    }

    return url;
  });

  _exports.default = _default;
});