define("ds-ssdc/controllers/dashboard/congress", ["exports", "@ember-decorators/object"], function (_exports, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _dec11, _dec12, _dec13, _dec14, _dec15, _dec16, _dec17, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8, _temp;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let DashboardCongressController = (_dec = Ember.inject.service, _dec2 = Ember.inject.controller('dashboard'), _dec3 = Ember._tracked, _dec4 = Ember._tracked, _dec5 = Ember._tracked, _dec6 = Ember._tracked, _dec7 = Ember._tracked, _dec8 = Ember._tracked, _dec9 = (0, _object.observes)("dashboardController.congresses.[]"), _dec10 = (0, _object.observes)("model.congressId"), _dec11 = Ember._action, _dec12 = Ember._action, _dec13 = Ember._action, _dec14 = Ember._action, _dec15 = Ember._action, _dec16 = Ember._action, _dec17 = Ember._action, (_class = (_temp = class DashboardCongressController extends Ember.Controller {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "ajax", _descriptor, this);

      _initializerDefineProperty(this, "dashboardController", _descriptor2, this);

      _initializerDefineProperty(this, "folderId", _descriptor3, this);

      _initializerDefineProperty(this, "dateRange", _descriptor4, this);

      _initializerDefineProperty(this, "selectedDateType", _descriptor5, this);

      _initializerDefineProperty(this, "dateType", _descriptor6, this);

      _initializerDefineProperty(this, "tenants", _descriptor7, this);

      _initializerDefineProperty(this, "selectedTenant", _descriptor8, this);
    }

    /* eslint-disable ember/no-observers */
    setCongressId() {
      if (this.dashboardController.congresses.length && this.target.currentRouteName === 'dashboard.congress.index' && this.model.congressId === 'loading') {
        const congressId = this.dashboardController.congresses[0].id;
        this.transitionToRoute('dashboard.congress.content', congressId);
      }

      this.dashboardController.selectedCongress = this.dashboardController.congresses.find(c => c.id + "" === this.model.congressId);
    }

    initCongress() {
      this.dashboardController.selectedCongress = this.dashboardController.congresses.find(c => c.id + "" === this.model.congressId);
      this.getFolderId();
      this.getTenants();
    }
    /* eslint-enable ember/no-observers */


    async getTenants() {
      try {
        if (this.model.congressId === 'loading' || !this.model.congressId) return;
        const response = await this.ajax.request(`/sessions/tenants`, {
          data: {
            congress_id: this.model.congressId
          },
          method: "POST",
          contentType: "application/json;charset=utf-8",
          dataType: "json"
        });

        if (response) {
          const tenants = [{
            id: 'common',
            name: 'public'
          }];

          for (var id in response) {
            tenants.pushObject({
              id: id,
              name: response[id]
            });
          }

          this.set('tenants', tenants);
        }
      } catch (error) {// TODO: Handle error for this web service
        // console.log(error);
      }
    }

    async getFolderId() {
      try {
        let data = {};
        if (this.model.congressId === 'loading' || !this.model.congressId) return;

        if (this.target.currentRoute && this.target.currentRoute.name === 'dashboard.congress.content' && this.selectedTenant !== null) {
          data['tenant_id'] = this.selectedTenant.id;
        }

        this.folderId = await this.ajax.request(`/sessions/box_folder/${this.model.congressId}`, {
          data,
          method: "POST",
          contentType: "application/json;charset=utf-8",
          dataType: "json"
        });
      } catch (error) {
        this.folderId = null;
        throw error;
      }
    }

    async generateTarget(congressId) {
      try {
        await this.ajax.request(`/contents/generate_target`, {
          data: {
            congress_id: congressId
          },
          method: "POST",
          contentType: "application/json;charset=utf-8",
          dataType: "json"
        });
        alert("Target generated Successfully");
      } catch (error) {
        alert("Target generated Successfully");
        throw error;
      }
    }

    async createBoxFolders() {
      try {
        await this.ajax.request(`sessions/generate_box_folders/${this.model.congressId}`, {
          method: "PATCH",
          contentType: "application/json;charset=utf-8",
          dataType: "json"
        });
        this.getFolderId();
        alert("Folders created successfully");
      } catch (error) {
        alert("Couldn't create folders");
      }
    }

    onChange(date) {
      if (date.length == 1) {
        return false;
      }

      if (date.length == 0) {
        this.set('dateRange.after', null);
        this.set('dateRange.before', null);
      } else {
        this.set('dateRange.after', date[0]);
        this.set('dateRange.before', date[1]);
      }
    }

    onReady(_selectedDates, _dateStr, instance) {
      this.flatpickrRef = instance;
    }

    selectTenant(tenant) {
      this.set('selectedTenant', tenant);
      this.getFolderId();
    }

    dateTypeFilter(selectedDateType) {
      this.selectedDateType = selectedDateType;
    }

    clearCalendar() {
      if (this.flatpickrRef) {
        this.flatpickrRef.clear();
      }
    }

  }, _temp), (_descriptor = _applyDecoratedDescriptor(_class.prototype, "ajax", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "dashboardController", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "folderId", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return null;
    }
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "dateRange", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return {
        before: null,
        after: null
      };
    }
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "selectedDateType", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return {
        'id': '',
        'name': '--Select---'
      };
    }
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "dateType", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return [{
        'id': '',
        'name': '--Select---'
      }, {
        'id': 'created',
        'name': 'created'
      }, {
        'id': 'published',
        'name': 'published'
      }];
    }
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "tenants", [_dec7], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return [{
        id: 'common',
        name: 'public'
      }];
    }
  }), _descriptor8 = _applyDecoratedDescriptor(_class.prototype, "selectedTenant", [_dec8], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return {
        id: 'common',
        name: 'public'
      };
    }
  }), _applyDecoratedDescriptor(_class.prototype, "setCongressId", [_dec9], Object.getOwnPropertyDescriptor(_class.prototype, "setCongressId"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "initCongress", [_dec10], Object.getOwnPropertyDescriptor(_class.prototype, "initCongress"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "generateTarget", [_dec11], Object.getOwnPropertyDescriptor(_class.prototype, "generateTarget"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "createBoxFolders", [_dec12], Object.getOwnPropertyDescriptor(_class.prototype, "createBoxFolders"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "onChange", [_dec13], Object.getOwnPropertyDescriptor(_class.prototype, "onChange"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "onReady", [_dec14], Object.getOwnPropertyDescriptor(_class.prototype, "onReady"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "selectTenant", [_dec15], Object.getOwnPropertyDescriptor(_class.prototype, "selectTenant"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "dateTypeFilter", [_dec16], Object.getOwnPropertyDescriptor(_class.prototype, "dateTypeFilter"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "clearCalendar", [_dec17], Object.getOwnPropertyDescriptor(_class.prototype, "clearCalendar"), _class.prototype)), _class));
  _exports.default = DashboardCongressController;
});