define("ds-ssdc/authenticators/custom", ["exports", "ember-simple-auth/authenticators/base"], function (_exports, _base) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _class, _descriptor, _temp;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let Custom = (_dec = Ember.inject.service, (_class = (_temp = class Custom extends _base.default {
    constructor(...args) {
      super(...args);

      _defineProperty(this, "tokenEndpoint", `/auth/login`);

      _defineProperty(this, "tokenRevocationEndpoint", `/auth/logout`);

      _initializerDefineProperty(this, "ajax", _descriptor, this);
    }

    restore(data) {
      return new Ember.RSVP.Promise(function (resolve, reject) {
        if (data.token) {
          resolve(data);
        } else {
          reject();
        }
      });
    }

    async authenticate(credentials) {
      if (credentials.token) {
        this.restore(credentials);
        return {
          token: credentials.token
        };
      }

      const enc = btoa(credentials.identification + ":" + credentials.password);
      const headers = {
        Authorization: `Basic ${enc}`
      };
      const {
        xhr
      } = this.ajax.request(this.tokenEndpoint, {
        type: "POST",
        headers,
        data: JSON.stringify({
          username: credentials.identification,
          password: credentials.password
        }),
        processData: false,
        dataType: "text"
      });

      try {
        let response = await xhr.promise();
        var token = xhr.getResponseHeader("Authorization");

        try {
          if (!token) {
            let responseJSON = JSON.parse(response);
            token = responseJSON && responseJSON.token;
          }
        } catch (err) {
          throw err; // Response body is not a valid json
          // Token not found in response body
        }

        return token ? {
          token
        } : false;
      } catch (error) {
        throw error;
      }
    }

    async invalidate() {
      try {
        const {
          xhr
        } = this.ajax.request(this.tokenRevocationEndpoint, {
          type: "POST",
          dataType: "json"
        });
        await xhr.promise();
      } catch (error) {
        return false;
      }

      return true;
    }

  }, _temp), (_descriptor = _applyDecoratedDescriptor(_class.prototype, "ajax", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class));
  _exports.default = Custom;
});