define("ds-ssdc/routes/dashboard/congress", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class DashboardCongressRoute extends Ember.Route {
    model(params) {
      return {
        congressId: params.congress_id
      };
    }

  }

  _exports.default = DashboardCongressRoute;
});