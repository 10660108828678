define("ds-ssdc/components/session-edit", ["exports", "@glimmer/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _temp;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    {{#if (or this.showTranscriptionPage this.showVerificationPage)}}
  {{#if this.showTranscriptionPage}}
      <LinkTo class="tab-link" @route="sessions.session.slides-transcription" @model="{{@row.deck_id}}">
      <div class="btn btn-small">
          {{this.status}}
      </div>
      </LinkTo>
  {{/if}}
  {{#if this.showVerificationPage}}
      <LinkTo class="tab-link" @route="sessions.session.slides-verification" @model="{{@row.deck_id}}">
          <div class="btn btn-small">
              {{this.status}}
          </div>
      </LinkTo>
  {{/if}}
  {{else}}
      {{this.status}}
  {{/if}}
  
  */
  {
    id: "TLlRXyNl",
    block: "{\"symbols\":[\"@row\"],\"statements\":[[4,\"if\",[[28,\"or\",[[23,0,[\"showTranscriptionPage\"]],[23,0,[\"showVerificationPage\"]]],null]],null,{\"statements\":[[4,\"if\",[[23,0,[\"showTranscriptionPage\"]]],null,{\"statements\":[[0,\"    \"],[5,\"link-to\",[[12,\"class\",\"tab-link\"]],[[\"@route\",\"@model\"],[\"sessions.session.slides-transcription\",[29,[[23,1,[\"deck_id\"]]]]]],{\"statements\":[[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"btn btn-small\"],[8],[0,\"\\n        \"],[1,[23,0,[\"status\"]],false],[0,\"\\n    \"],[9],[0,\"\\n    \"]],\"parameters\":[]}],[0,\"\\n\"]],\"parameters\":[]},null],[4,\"if\",[[23,0,[\"showVerificationPage\"]]],null,{\"statements\":[[0,\"    \"],[5,\"link-to\",[[12,\"class\",\"tab-link\"]],[[\"@route\",\"@model\"],[\"sessions.session.slides-verification\",[29,[[23,1,[\"deck_id\"]]]]]],{\"statements\":[[0,\"\\n        \"],[7,\"div\",true],[10,\"class\",\"btn btn-small\"],[8],[0,\"\\n            \"],[1,[23,0,[\"status\"]],false],[0,\"\\n        \"],[9],[0,\"\\n    \"]],\"parameters\":[]}],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[]},{\"statements\":[[0,\"    \"],[1,[23,0,[\"status\"]],false],[0,\"\\n\"]],\"parameters\":[]}]],\"hasEval\":false}",
    meta: {
      moduleName: "ds-ssdc/components/session-edit.hbs"
    }
  });

  let SessionEditComponent = (_dec = Ember._tracked, _dec2 = Ember._tracked, _dec3 = Ember._tracked, _dec4 = Ember._tracked, _dec5 = Ember._tracked, _dec6 = Ember.computed("args.{value,row.status}"), (_class = (_temp = class SessionEditComponent extends _component.default {
    get status() {
      const status = this.args.row.get("status");
      let showTranscriptionPage = false,
          showVerificationPage = false;

      switch (true) {
        case status === "Slides Generated" || status === "Slides Verified":
          showVerificationPage = true;
          showTranscriptionPage = false;
          break;

        case status === "Generating Transcripts" || status === "Transcripts Generation Failed":
          showVerificationPage = true;
          showTranscriptionPage = false;
          break;

        case status === "Transcripts Generated":
          showVerificationPage = false;
          showTranscriptionPage = true;
          break;

        case status === "Transcripts Verified":
          showVerificationPage = false;
          showTranscriptionPage = true;
          break;

        default:
          showTranscriptionPage = false;
          showVerificationPage = false;
          break;
      }

      const setStatus = () => {
        this.showVerificationPage = showVerificationPage;
        this.showTranscriptionPage = showTranscriptionPage;
      };

      Ember.run.scheduleOnce("afterRender", this, setStatus);
      return this.value;
    }

    constructor() {
      super(...arguments);

      _initializerDefineProperty(this, "value", _descriptor, this);

      _initializerDefineProperty(this, "column", _descriptor2, this);

      _initializerDefineProperty(this, "row", _descriptor3, this);

      _initializerDefineProperty(this, "showTranscriptionPage", _descriptor4, this);

      _initializerDefineProperty(this, "showVerificationPage", _descriptor5, this);

      this.value = this.args.value;
      this.row = this.args.row;
      this.column = this.args.column;
    }

  }, _temp), (_descriptor = _applyDecoratedDescriptor(_class.prototype, "value", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "column", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "row", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "showTranscriptionPage", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "showVerificationPage", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _applyDecoratedDescriptor(_class.prototype, "status", [_dec6], Object.getOwnPropertyDescriptor(_class.prototype, "status"), _class.prototype)), _class));
  _exports.default = SessionEditComponent;

  Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, SessionEditComponent);
});