define("ds-ssdc/services/ajax", ["exports", "ember-ajax/services/ajax", "ds-ssdc/config/environment", "ember-ajax/errors", "jquery"], function (_exports, _ajax, _environment, _errors, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _class, _descriptor, _descriptor2, _temp;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let AjaxService = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember.computed('session.data.authenticated.token'), (_class = (_temp = class AjaxService extends _ajax.default {
    constructor(...args) {
      super(...args);

      _defineProperty(this, "contentType", 'application/json; charset=utf-8');

      _initializerDefineProperty(this, "session", _descriptor, this);

      _initializerDefineProperty(this, "router", _descriptor2, this);

      _defineProperty(this, "host", (_environment.default.APP.host || `${window.location.protocol}//${window.location.host}/`) + _environment.default.APP.apiEndPoint);
    }

    get headers() {
      let headers = {};
      const authToken = this.get("session.data.authenticated.token");

      if (authToken) {
        headers["Authorization"] = `Bearer ${authToken}`;
      }

      return headers;
    }

    async refreshTokenRequest() {
      return new Promise((resolve
      /*, reject*/
      ) => {
        const headers = {};
        headers['Authorization'] = 'Bearer ' + this.session.data.authenticated.token;
        headers["Content-Type"] = "application/json";

        try {
          const host = this.host || '';
          this.session.refreshToken = true;

          _jquery.default.ajax({
            url: `${host}auth/refresh`,
            type: "POST",
            headers
          }).then(async (response
          /*statusText, xhr*/
          ) => {
            var token = response.access_token;

            if (token) {
              Ember.set(this, "session.data.authenticated.token", token);
              await this.session.authenticate("authenticator:custom", {
                token
              });
              Ember.run(() => {
                this.session.refreshToken = false;
                resolve({
                  resendRequest: true
                });
              });
            } else {
              if (this.session.get("isAuthenticated")) {
                this.session.invalidate();
              }

              Ember.run(() => {
                this.session.refreshToken = false;
                resolve(false);
              });
            }
          }, () => {
            if (this.session.get("isAuthenticated")) {
              this.session.invalidate();
            }

            Ember.run(function () {
              Ember.set('session.refreshToken', false);
              resolve(false);
            });
          });
        } catch (error) {
          if (this.session.get("isAuthenticated")) {
            this.session.invalidate();
          }
        }
      });
    }

    request() {
      // TODO: Handle refresh token
      return super.request(...arguments).catch(error => {
        if ((0, _errors.isUnauthorizedError)(error) && !this.session.refreshToken) {
          this.refreshTokenRequest().then(response => {
            if (response.resendRequest && response.resendRequest === true) {
              let currentRouteName = this.router.currentRouteName;
              const currentRouteInstance = Ember.getOwner(this).lookup(`route:${currentRouteName}`);
              currentRouteInstance.refresh();
            }
          });
          return;
        } else if ((0, _errors.isForbiddenError)(error)) {// console.error(error);
          // this.toast.error(error.message);
          // this.router.transitionTo('projects');
        } else {
          throw error;
        } // let er = error.payload && error.payload.errors ? error.payload : error;

      });
    }

  }, _temp), (_descriptor = _applyDecoratedDescriptor(_class.prototype, "session", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "router", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "headers", [_dec3], Object.getOwnPropertyDescriptor(_class.prototype, "headers"), _class.prototype)), _class));
  _exports.default = AjaxService;
});